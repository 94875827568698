
































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Player from '@/components/_uikit/Player.vue'
import Recorder from '@/components/_uikit/Recorder.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
// store
import { EducationLargeExerciseResource, EducationLargeTaskDecideSpeakingQuestionRequest, EducationLargeTaskResource, EducationLargeTaskSpeakingQuestionResource, ExerciseStatus, MediaResource } from '@/store/types'

@Component({
  components: {
    Player,
    Recorder,
    UploadInput,
  },
})
export default class QuestionAudioSingleForm extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskSpeakingQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private questionForm!: EducationLargeTaskDecideSpeakingQuestionRequest

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get isStatusWait() {
    return this.task.status.value === ExerciseStatus.WAIT || this.task.status.value === ExerciseStatus.IN_PROCESS
  }

  private get uploadAudioParams() {
    return [{
      name: 'type',
      value: 'audio',
    }]
  }

  private isRecording = false
  private innerMedia: MediaResource | null = null
  private innerMediaId: number | null = null
  private isLoadingAudio = false

  private handleBeforeRecording() {
    this.isRecording = true
  }

  private handleAfterRecording() {
    this.isLoadingAudio = true
    this.isRecording = false
  }

  private handleMicFailed() {
    this.isRecording = false
  }

  private mounted() {
    if (this.question.answers[0] && this.question.answers[0].media) {
      this.innerMedia = this.question.answers[0].media
      this.innerMediaId = this.innerMedia.id
    }
  }

  private deleteAnswer() {
    this.innerMediaId = null
    this.innerMedia = null
    this.questionForm.answers = []
  }

  private uploadedAnswer(media: MediaResource) {
    this.isLoadingAudio = false
    this.innerMedia = media
    this.innerMediaId = media.id
    this.questionForm.answers = [{
      mediaId: this.innerMediaId,
      questionsId: this.question.id,
    }]
  }
}
