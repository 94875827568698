


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import TaskScore from '@/components/views/exercise/TaskScore.vue'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    TaskScore,
  },
})
export default class MistakesModal extends Vue {
  @Prop({ required: true })
  private points!: number

  @Prop({ required: true })
  private maxPoints!: number

  @Prop({ default: false })
  private visible!: boolean

  private localVisible = this.visible

  private handleClose () {
    this.localVisible = false
    this.$emit('closeModal')
  }

  private handleMistakes() {
    this.$bus.$emit('startMistakes')
    this.handleClose()
  }

  @Watch('visible')
  watchVisible(value: boolean) {
    this.localVisible = value
  }
}
