






















































































import { Component, Prop, Vue } from 'vue-property-decorator'

// components
import Player from '@/components/_uikit/Player.vue'
import Recorder from '@/components/_uikit/Recorder.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
// store
import { EducationLargeExerciseResource, EducationLargeTaskDecideSpeakingQuestionAnswerRequest, EducationLargeTaskDecideSpeakingQuestionRequest, EducationLargeTaskResource, EducationLargeTaskSpeakingQuestionResource, ExerciseStatus, MediaResource } from '@/store/types'

@Component({
  components: {
    Player,
    Recorder,
    UploadInput,
  },
})
export default class QuestionAudioQueueForm extends Vue {
  @Prop({ required: true })
  private question!: EducationLargeTaskSpeakingQuestionResource

  @Prop({ required: true })
  private questionIndex!: number

  @Prop({ required: true })
  private questionForm!: EducationLargeTaskDecideSpeakingQuestionRequest

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  private get isStatusWait() {
    return this.task.status.value === ExerciseStatus.WAIT || this.task.status.value === ExerciseStatus.IN_PROCESS
  }

  private get uploadAudioParams() {
    return [{
      name: 'type',
      value: 'audio',
    }]
  }

  private isRecording = false
  private isLoading = true
  private step = 1
  private showAnswers = false
  private isLoadingAudio = false

  private prevStep(step: number) {
    this.step = step - 1
  }

  private nextStep(step: number, notAccessNextStep = false) {
    if (!notAccessNextStep)
      this.step = step + 1
  }

  private handleBeforeRecording() {
    this.isRecording = true
  }

  private handleAfterRecording() {
    this.isLoadingAudio = true
    this.isRecording = false
  }

  private handleMicFailed() {
    this.isRecording = false
  }

  private innerMedia: { [key: number]: MediaResource | null } = {}
  private innerMediaIds: { [key: number]: number | null } = {}

  private mounted() {
    if (this.question.answers.length) {
      this.question.answers.map(answer => {
        this.innerMedia[answer.questionId] = answer.media || null
        this.innerMediaIds[answer.questionId] = answer.media ? answer.media.id : null
      })
    }
    this.isLoading = false
  }

  private deleteAnswer(id: number) {
    delete this.innerMediaIds[id]
    delete this.innerMedia[id]
    this.innerMedia = { ...this.innerMedia }
    this.innerMediaIds = { ...this.innerMediaIds }
    this.questionForm.answers = this.questionForm.answers.filter((answer: EducationLargeTaskDecideSpeakingQuestionAnswerRequest) => answer.questionsId !== id)
  }

  private uploadedAnswer(media: MediaResource, id: number) {
    this.isLoadingAudio = false
    this.innerMedia = {
      ...this.innerMedia,
      [id]: media,
    }
    this.innerMediaIds = {
      ...this.innerMediaIds,
      [id]: media.id,
    }
    this.questionForm.answers.push({ mediaId: media.id, questionsId: id })
    setTimeout(() => {
      if (this.step < this.question.questions.length) {
        this.step++
      } else {
        this.showAnswers = true
      }
    }, 500)
  }
}
